export default [
    [{
        method: 'from',
        options: {
            autoAlpha: 0,
            duration: 0.1
        }
    },{
        method: 'from',
        options: {
            scale: 1.6,
            duration: 1,
        }
    },
    {
        method: 'from',
        options: {
            filter: 'blur(20px)',
            duration: 0.3,
        },
        timing: '<'
    },{
        method: 'to',
        options: {
            x: -370,
            ease: 'expo.in',
            duration: 1.1,
        }
    },{
        method: 'set',
        options: {
            scaleX: -1,
        },
        timing: '-=.2'
    },{
        method: 'to',
        options: {
            scaleX: -1.2,
            scaleY: 1.2,
            rotate: 4,
            duration: .01
        },
        timing: '-=.19'
    },{
        method: 'to',
        options: {
            scaleX: -1,
            scaleY: 1,
            rotate: 0,
            duration: .7,
            ease: 'elastic.out',
        }
    },{
        method: 'to',
        options: {
            scaleX: -4,
            scaleY: 4,
            duration: 1,
            ease: 'expo.out',
        },
    },{
        method: 'to',
        options: {
            opacity: 0,
            duration: 0.2,
        },
        timing: '-=.2'
    }],
    [{
        method: 'from',
        options: {
            autoAlpha: 0,
            duration: 0.1
        },
        timing: '-=1'
    },{
        method: 'from',
        options: {
            x: -200,
            duration: 1.4,
        }
    },{
        method: 'to',
        options: {
            scale: 1.7,
            duration: 1,
            ease: 'expo.out'
        },
        timing: '-=.8'
    },{
        method: 'to',
        options: {
            x: -30,
            duration: .6,
        },
        timing: '-=.6'
    },{
        method: 'to',
        options: {
            autoAlpha: 0,
            duration: 0.1
        },
    }],
    [{
        method: 'set',
        options: {
            zIndex: 30
        }
    },{
        method: 'from',
        options: {
            autoAlpha: 0,
            duration: 0.1
        },
        timing: '-=.2'
    },{
        method: 'from',
        options: {
            scale: 10,
            duration: 1,
            filter: 'blur(20px)',
            ease: 'expo.out',
        },
        timing: '-=.2'
    },{
        method: 'to',
        options: {
            x: 100,
            duration: 1,
        },
        timing: '-=.7'
    },{
        method: 'to',
        options: {
            rotation: -2,
            duration: .5,
        },
        timing: '-=.4'
    },{
        method: 'to',
        options: {
            autoAlpha: 0,
            duration: 0.1
        },
    }],
    [{
        method: 'set',
        options: {
            zIndex: 30
        }
    },{
        method: 'from',
        options: {
            autoAlpha: 0,
            duration: 0.1
        },
        timing: '-=.2'
    },{
        method: 'from',
        options: {
            scale: 10,
            duration: 1,
            filter: 'blur(20px)',
            ease: 'expo.out',
        },
        timing: '-=.2'
    },{
        method: 'to',
        options: {
            x: -100,
            duration: 1,
        },
        timing: '-=.7'
    },{
        method: 'to',
        options: {
            rotation: 2,
            duration: .5,
        },
        timing: '-=.4'
    },{
        method: 'to',
        options: {
            autoAlpha: 0,
            duration: 0.1
        },
    }]
];