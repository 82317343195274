<template>
    <div class="dynamic-video">
        <div
            ref="price"
            class="product-price-wrapper">
            <div class="product-price">
                {{ price }}
            </div>
        </div>
        <div
            v-if="title"
            ref="title"
            class="product-title">
            <span>{{ title | truncate(30) }}</span>
        </div>
        <div class="image-wrapper">
            <div
                v-for="(image, index) in images"
                :key="`${image}-${index}`"
                ref="images"
                class="image-container"
                :style="{
                    zIndex: 10 + (images.length - 1) - index
                }">
                <div class="image-container-inner">
                    <img
                        :src="image"
                        class="image-left">
                    <img
                        :src="image"
                        class="image">
                    <img
                        :src="image"
                        class="image-right">
                </div>
            </div>
        </div>
        <img
            :src="mainImage"
            class="background-image">
    </div>
</template>

<script>
import getTimeline from '../getTimeline';

export default {
    props: {
        adData: {
            type: [Object, Array],
            required: true
        },
        muted: {
            type: Boolean,
            default: false
        },
        isVideoPlaying: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            complete: false,
        };
    },
    computed: {
        title() {
            return this.product?.title;
        },
        product() {
            return this.adData.products[Math.floor(Math.random() * this.adData.products.length)];
        },
        images() {
            if (!this.product?.images?.length) {
                return [];
            }

            // Cloudinary images are not working at the moment
            const workingImages = this.product?.images?.filter(image => !image.includes('cloudinary'));

            return new Array(10).fill('').map((item, index) => {
                if (workingImages[index]) {
                    return workingImages[index];
                }

                // If there's no image in the position
                // begin repeating the order or just continue
                // returning the first
                return (workingImages[(index + 1) % 5] ?? workingImages[0]);
            });
        },
        mainImage() {
            return this.images[0] ?? '';
        },
        price() {
            return this.$formatNumber(
                this.product.price,
                this.$numberFormatsAbbr.currency
            );
        }
    },
    watch: {
        isVideoPlaying() {
            // Create the timeline if it hasn't been
            if (!this.tl) {
                this.tl = getTimeline(this.$refs, () => {
                    this.complete = true;
                });
            }

            if (this.complete) {
                this.complete = false;
                this.tl.restart();
            } else if (this.isVideoPlaying) {
                // While it may seem confusing, the control of the video playing/not is done by the parent component
                // If this prop is true, then we want to play/pause the video on the children/this component
                this.tl.play();
            } else {
                this.tl.pause();
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.dynamic-video {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.product-title {
    position: absolute;
    top: 52%;
    left: 40px;
    z-index: 40;
    opacity: 0;
    filter: drop-shadow(0 0 1px $black);

    span {
        position: relative;
        display: block;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        padding-left: 8px;

        &::after {
            content: '';
            position: absolute;
            right: 100%;
            height: 100%;
            width: 4px;
            background: $white;

        }
    }
}

.product-price-wrapper {
    position: absolute;
    background: white;
    z-index: 40;
    top: 14%;
    right: 15%;
    padding: 8px 0;
    border: 1px solid black;
    box-shadow: 5px 5px 0 1px black;
    opacity: 0;
}

.product-price {
    color: black;
    font-weight: 700;
    font-size: 22px;
    transform: translateX(-10px);
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 50%;
        background: white;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
        top: 0;
        height: 100%;
        left: -5px;
        z-index: -1;
    }
}

.image-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 1;
}

.image-container-inner {
    position: relative;
}

.image-left {
    position: absolute;
    right: 100%;
    height: 100%;
    top: 0;
    transform: scaleX(-1);
}

.image {
    max-width: 100%;
    height: auto;
    display: block;
}

.image-right {
    position: absolute;
    left: 100%;
    height: 100%;
    top: 0;
    transform: scaleX(-1);
}

.background-image {
    position: absolute;
    top: -20px;
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    object-fit: cover;
    filter: blur(10px) brightness(70%);
}
</style>
