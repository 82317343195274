import gsap from 'gsap';
import animations from './animations';

let nextAnimation = 0;
const getNextAnimation = () => {
    const animation = animations[nextAnimation];
    if (nextAnimation === (animations.length - 1)) {
        nextAnimation = 0;
    } else {
        nextAnimation++;
    }
    return animation;
};

const addAnimation = (tl, elem) => {
    const sequence = getNextAnimation();
    sequence.forEach(config => {
        tl[config.method](elem, { ...config.options }, config.timing);
    });
};

const getImagesTimeline = (images) => {
    const tl = gsap.timeline();
    images.forEach((image, index) => {
        // Add the next series of available animations
        // to this image
        //const timing = index > 0 ? '-=1' : 0;
        //tl.from(image, .05, { autoAlpha: 0 });
        addAnimation(tl, image, index);
        //tl.to(image, .05, { autoAlpha: 0 });
    });
    return tl;
};


const getTitleTimeline = (title) => {
    const tl = gsap.timeline();
    tl.set(title, {
        opacity: 0
    });
    tl.to(title, {
        opacity: 1,
        duration: 0.2
    }, 0.1);
    tl.to(title, {
        opacity: 0,
        duration: .2
    }, 7);

    return tl;
};

const getPriceTimeline = (price) => {
    const tl = gsap.timeline();
    tl.set(price, {
        opacity: 0
    });
    tl.to(price, {
        opacity: 1,
        duration: 1
    }, 1);
    tl.to(price, {
        opacity: 0,
        duration: .5
    }, 9);

    return tl;
};

const getTimeline = ({ price, title, images, audioPlayer }, onComplete) => {
    const tl = gsap.timeline({ paused: true });


    // Ensure the volume is up when the timeline starts
    tl.set(audioPlayer, {
        volume: 1,
        currentTime: 0
    });

    // Add sub-timelines to all start at 0
    tl.add(getPriceTimeline(price), 0);
    tl.add(getTitleTimeline(title), 0);
    tl.add(getImagesTimeline(images), 0);

    // Fade out the music before the animation ends
    tl.to(audioPlayer, {
        volume: 0,
        duration: 0.5,
        // Exploit this as the last animation since
        // tl.then doesn't work when it restarts
        onComplete
    }, '>');

    return tl;
};

export default getTimeline;