<template>
    <button class="player-control">
        <icon
            size="75"
            :color="color"
            :name="icon" />
    </button>
</template>

<script>
import Icon from '@/components/globals/Icon';

export default {
    components: {
        Icon,
    },
    props: {
        paused: {
            type: Boolean,
            required: true
        },
        complete: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'black'
        },
    },
    computed: {
        icon() {
            return this.paused ? 'video-play' : 'video-pause';
        }
    }
};
</script>

<style lang="scss" scoped>
.player-control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    svg {
        opacity: 0.5;
    }
}
</style>
