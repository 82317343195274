<template>
    <div
        class="preview-container"
        @mouseover="showPlayButtons"
        @mouseleave="hidePlayButtons">
        <div class="preview-ui">
            <img
                class="preview-header"
                src="/img/tiktok-header.png"
                alt="tiktok-preview-header">
            <tik-tok-dynamic-video
                v-if="isDynamic"
                :muted="muted"
                :is-video-playing="isVideoPlaying"
                :ad-data="adData" />
            <tik-tok-video
                v-else-if="assetType === VIDEO"
                :muted="muted"
                :media-url="mediaUrl"
                :is-video-playing="isVideoPlaying" />
            <tik-tok-collection
                v-else-if="assetType === COLLECTION"
                :ad-data="adData"
                :is-video-playing="isVideoPlaying" />
            <div
                v-if="shouldShowVideoControls"
                class="play-wrapper"
                @click="playVideo">
                <div class="play">
                    <icon
                        v-if="!isVideoPlaying"
                        size="40"
                        color="white"
                        name="audio-play" />
                    <div v-else>
                        <div class="previews-pause-bar" />
                        <div class="previews-pause-bar" />
                    </div>
                </div>
            </div>

            <video
                v-show="false"
                ref="audioPlayer"
                class="audio-player"
                :muted="muted">
                <source
                    :src="audioTrack"
                    type="audio/mpeg">
            </video>

            <div class="preview-content">
                <p class="user">
                    @{{ pageName }}
                    <img
                        src="/img/tiktok-verified.png"
                        width="12"
                        height="12"
                        alt="tiktok-verified-logo">
                </p>
                <div class="primary-text">
                    <div
                        ref="primary"
                        style="line-height:21px">
                        {{ primary }}
                        <span
                            v-if="seeMore"
                            @click="openPrimary">... <span class="see-more">Sponsored</span></span>
                    </div>
                </div>
                <p class="sound-track">
                    <img
                        class="mr-2"
                        src="/img/tiktok-melody-icon.png"
                        width="13"
                        height="13"
                        alt="tiktok-soundtrack-icon">
                    Soundtrack
                </p>
                <a
                    :href="ctaLink"
                    target="_blank">
                    {{ ctaText }}
                    <icon
                        class="cta-icon"
                        name="chevron-right"
                        color="white"
                        size="10" />
                </a>
            </div>
            <div class="actions-column">
                <div class="preview-logo">
                    YOUR
                    LOGO
                </div>
                <img
                    class="actions-icons"
                    src="/img/tiktok-actions-column.png"
                    alt="action-icons">
            </div>
            <div class="music-player">
                <div class="account-logo" />
            </div>
        </div>
        <div class="navigation-bar">
            <img
                src="/img/tiktok-navigation-bar.png"
                alt="tiktok-navigation-bar">
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { mapGetters, mapState } from 'vuex';
import { IMAGE, VIDEO, COLLECTION } from '@/helpers/globals';
import TikTokCollection from '@/components/globals/AdPreview/tiktok/AssetTypes/TikTokCollection';
import TikTokVideo from '@/components/globals/AdPreview/tiktok/AssetTypes/TikTokVideo.vue';
import TikTokDynamicVideo from './AssetTypes/TikTokDynamicVideo.vue';

export default {
    name: 'TikTokPreview',
    components: {
        TikTokVideo,
        TikTokCollection,
        TikTokDynamicVideo,
        Icon,
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true
        },
        muted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            IMAGE,
            VIDEO,
            COLLECTION,
            isVideoPlaying: false,
            controlsActive: true,
            defaultLogoUrl: 'https://play-lh.googleusercontent.com/KxeSAjPTKliCErbivNiXrd6cTwfbqUJcbSRPe_IBVK_YmwckfMRS1VIHz-5cgT09yMo=w480-h960-rw',
            seeMore: false,
            primary: '',
            fullPrimary: '',
            tikTokTracks: [
                'https://sf16-sg-default.akamaized.net/obj/tiktok-obj/67d8d24a5e66b544db9dec9eb5f9aabe.mp3',
                'https://sf16-sg-default.akamaized.net/obj/tiktok-obj/86474dcc83f48f1057f19dd525a4ed90.mp3',
                'https://sf16-sg-default.akamaized.net/obj/tiktok-obj/df3d4e1830a1128bdebf83bedac8df69.mp3',
                'https://sf16-sg-default.akamaized.net/obj/tiktok-obj/cedfc65c9f83bc7d2d8c5b6593429a1b.mp3',
                'https://sf16-sg-default.akamaized.net/obj/tiktok-obj/8fc1b1f7cde897434e3472ec93ba6af3.mp3'
            ],
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters(['dealerPrimaryLogo']),
        pageName() {
            return `${this.adData?.platform_specific_fields?.brand_name || this.currentDealer.name}`;
        },
        pageLogo() {
            return this.dealerPrimaryLogo?.url || this.defaultLogoUrl;
        },
        profileName() {
            return this.adData?.platform_specific_fields?.profile?.name;
        },
        profileLogo() {
            return this.adData?.platform_specific_fields?.profile?.picture;
        },
        isDynamic() {
            return Boolean(!this.mediaUrl && this.adData?.products?.length);
        },
        firstAsset() {
            return this.creatives[0]?.assets?.[0];
        },
        mediaUrl() {
            return this.firstAsset?.url || this.adData?.media_items[0].media_url;
        },
        creatives() {
            return this.adData?.creatives ?? [];
        },
        ctaLink() {
            return this.adData?.cta_link || this.creatives[0]?.cta_link;
        },
        ctaText() {
            return this.adData?.cta_text || this.creatives[0]?.cta_text;
        },
        assetType() {
            if (this.adData.render_type === COLLECTION) {
                return COLLECTION;
            }

            return (this.creatives[0]?.media_type || this.firstAsset?.asset_type?.name)?.toUpperCase();
        },
        shouldShowVideoControls() {
            return this.controlsActive && ([COLLECTION, VIDEO].includes(this.assetType) || this.isDynamic);
        },
        audioTrack() {
            return this.tikTokTracks[Math.floor(Math.random() * this.tikTokTracks.length)];
        },
    },
    watch: {
        adData: {
            handler() {
                this.formatPrimaryText();
            },
            deep: true
        }
    },
    created() {
        this.checkIfPreviewIsValid();
        this.formatPrimaryText();
    },
    methods: {
        checkIfPreviewIsValid() {
            if ([VIDEO, COLLECTION].includes(this.assetType) || this.isDynamic) {
                this.$emit('set-video-type');
            }

            if (!this.isDynamic && !this.mediaUrl) {
                throw new TypeError('This type of ad is not supported!');
            }
        },
        formatPrimaryText() {
            // detailed formatting of primary text like we have it in FB business manager
            this.seeMore = false;
            let rawPrimaryText = this.adData.headline ?? this.adData.body_text;
            if (rawPrimaryText) {
                rawPrimaryText = rawPrimaryText.trim();
                this.primary = rawPrimaryText;
                this.fullPrimary = rawPrimaryText;
                const lines = rawPrimaryText.split(/\r\n|\r|\n/);
                this.checkLines(lines);
            }
        },
        openPrimary() {
            this.seeMore = false;
            this.primary = this.fullPrimary;
        },
        checkLines(lines) {
            this.$nextTick(function() {
                const primary = this.$refs.primary;
                const textHeight = primary.offsetHeight;
                const lineHeight = parseInt(primary.style.lineHeight);
                const textLines = textHeight / lineHeight;
                if (lines.length > 2) {
                    this.seeMore = true;
                    const shortenedText = [];
                    lines.forEach((line, index) => {
                        if (index < 2) {
                            shortenedText.push(line);
                        }
                    });
                    this.primary = shortenedText.join('\n');
                    this.primary = this.primary.trim();
                }

                if (lines[lines.length - 1] === '') {
                    lines.splice(lines.length - 1, 1);
                    this.checkLines(lines);
                    return;
                }

                if (textLines > 2) {
                    this.seeMore = true;
                    let lastLine = lines[lines.length - 1];
                    if (lastLine) {
                        const lineWords = lastLine.split(' ');
                        // eslint-disable-next-line no-unused-vars
                        const lastWord = lineWords.pop();
                        lines[lines.length - 1] = lineWords.join(' ');
                        const shortenedText = [];
                        lines.forEach(line => {
                            shortenedText.push(line);
                        });
                        this.primary = shortenedText.join('\n');
                        this.primary = this.primary.trim();
                        // must be recursive until we get desired length
                        setTimeout(() => {
                            this.checkLines(lines);
                        }, 0);
                    }
                }
            });
        },
        playVideo() {
            this.isVideoPlaying = !this.isVideoPlaying;

            if (this.isDynamic || this.assetType === COLLECTION) {
                this.toggleAudio();
            }
        },
        showPlayButtons() {
            this.controlsActive = true;
        },
        hidePlayButtons() {
            if (this.isVideoPlaying) {
                this.controlsActive = false;
            }
        },
        toggleAudio() {
            if (this.isVideoPlaying) {
                this.$refs.audioPlayer.play();
            } else {
                this.$refs.audioPlayer.pause();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.preview-container {
    position: relative;
    width: 375px;
    height: 751px;
    display: flex;
    flex-direction: column;
    background-color: $black;
    border-radius: 20px;
    overflow: hidden;
    color: $white;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
    @media only screen and (max-width: 400px) {
        width: 320px;
    }
}

.preview-ui {
    position: relative;
    flex: 1;
}

.preview-coming-soon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.image-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}

.preview-header {
    position: absolute;
    width: 100%;
    z-index: 50;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.preview-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 95px 10px 10px;
    z-index: 50;
    font-size: 15px;
    background-image: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.70) 100%);

    p {
        margin-bottom: 10px;

        img {
            vertical-align: middle;
        }
    }

    a {
        text-align: center;
        color: $white;
        background-color: #A80000;
        border-radius: 4px;
        display: block;
        text-decoration: none;
        padding: 10px 0;
        font-size: 12px;
        font-weight: bold;

        .cta-icon {
            vertical-align: middle;
        }
    }

    .user {
        font-size: 17px;
        margin-bottom: 5px;
        font-weight: bold;
    }
}

.primary-text {
    font-size: 14px;
    padding: 0 30px 15px 0;
    color: white;
    font-weight: 600;
    overflow: hidden;
    position: relative;
    white-space: pre-wrap;
}

.see-more {
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    font-size: 10px;
    background: white;
    padding: 0 3px;
    line-height: 16px;
    border-radius: 5px;
    transform: translateY(-2px);
    color: black;

    &:hover {
        text-decoration: underline;
    }
}

.actions-column {
    position: absolute;
    bottom: 88px;
    right: 10px;
    z-index: 50;
    width: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .preview-logo {
        position: relative;
        border-radius: 50%;
        border: 1px solid $white;
        background-color: black;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;
        text-align: center;
        font-weight: 900;
        font-size: 11px;
        line-height: 11px;

        &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 50%;
            width: 20px;
            height: 20px;
            transform: translateX(-50%);
            background: url('/img/tiktok-follow-button.png') no-repeat;
            background-size: cover;
        }

        img {
            max-width: 40px;
            max-height: 25px;
        }
    }

    img {
        max-width: 100%;
    }
}

.music-player {
    position: absolute;
    width: 77px;
    height: 52px;
    bottom: 10px;
    right: 10px;
    z-index: 50;
    background: url('/img/tiktok-music-player.png') no-repeat;
    background-size: cover;

    .account-logo {
        position: absolute;
        right: 0;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 40px;
            max-height: 25px;
        }
    }
}

.navigation-bar {
    height: 84px;
    padding-top: 10px;
    text-align: center;

    img {
        width: 353px;
    }
}

.play-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 50;

    .play {
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }

    &:after {
        content: '';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top: 50%;
        left: 50%;
        margin-top: -44px;
        margin-left: -45px;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        z-index: 1;
    }
}
</style>
