<template>
    <div
        @mouseover="hover = true"
        @mouseleave="hover = false">
        <video
            ref="videoPlayer"
            :key="src"
            class="video"
            :loop="loop"
            :muted="muted"
            :autoplay="autoplay"
            @click="togglePlay"
            @canplay="updatePlay"
            @playing="updatePlay"
            @pause="updatePlay">
            <source
                :src="src"
                type="video/mp4">
        </video>
        <player-control
            v-if="controls && hover"
            :color="color"
            :paused="paused"
            @click="togglePlay" />
    </div>
</template>

<script>
import PlayerControl from './PlayerControl';

export default {
    components: {
        PlayerControl
    },
    props: {
        src: {
            type: String,
            required: true
        },
        loop: {
            type: Boolean,
            default: true
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        muted: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
            default: 'black'
        },
    },
    data() {
        return {
            paused: true,
            hover: false
        };
    },
    methods: {
        updatePlay(event) {
            this.paused = event.target.paused;
        },
        togglePlay() {
            if (this.paused) {
                this.$refs.videoPlayer.play();
            } else {
                this.$refs.videoPlayer.pause();
            }
        }
    }
};

// Allow external use
export { PlayerControl };
</script>

<style lang="scss" scoped>
.video {
    width: 100%;
    vertical-align: top;
}
</style>
