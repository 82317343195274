<template>
    <div class="wrapper">
        <video-player
            ref="video"
            :controls="false"
            :muted="muted"
            :src="mediaUrl"
            class="preview-video" />
    </div>
</template>

<script>
import VideoPlayer from '@/components/globals/VideoPlayer/index.vue';

export default {
    name: 'TikTokVideo',
    components: {
        VideoPlayer,
    },
    props: {
        mediaUrl: {
            type: String,
            required: true,
        },
        muted: {
            type: Boolean,
            required: true,
        },
        isVideoPlaying: {
            type: Boolean,
            required: true,
        }
    },
    watch: {
        isVideoPlaying() {
            this.$refs.video.togglePlay();
        }
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    .preview-video {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
}
</style>
