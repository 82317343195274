<template>
    <div class="tiktok-carousel-container">
        <carousel
            loop
            adjustable-height
            :autoplay="isVideoPlaying"
            :per-page="1"
            :speed="2000"
            navigation-next-label=""
            navigation-prev-label="">
            <slide
                v-for="(slide, index) in imagesToUse"
                :key="index"
                class="carousel-slide">
                <div class="image-container">
                    <img
                        ref="image"
                        class="carousel-image"
                        alt="TikTok Carousel Preview"
                        :src="slide[keyToUse]">
                </div>
            </slide>
        </carousel>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { STATIC } from '@/helpers/globals';

export default {
    name: 'TikTokCollection',
    components: {
        Carousel,
        Slide
    },
    props: {
        adData: {
            type: Object,
            required: true,
        },
        isVideoPlaying: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        imagesToUse() {
            if (this.adData?.render_type === STATIC) {
                return this.adData?.creatives;
            }

            return this.adData?.products;
        },
        keyToUse() {
            if (this.adData?.render_type === STATIC) {
                return 'media_url';
            }

            return 'primary_image';
        }
    }
};
</script>

<style lang="scss" scoped>
.tiktok-carousel-container {
    overflow: hidden;
    height: 100%;

    .carousel-image {
        object-fit: contain;
        width: 100%;
        height: 667px;
    }
}
</style>

<style lang="scss">
.tiktok-carousel-container {
    .VueCarousel-pagination {
        position: relative;
        z-index: 999;
        margin-top: -250px;
    }

    .VueCarousel-dot {
        padding: 3px !important;
        width: 8px !important;
        height: 8px !important;
        opacity: 0.6;
    }

    .VueCarousel-dot--active {
        opacity: 1;
        background-color: $white !important;
    }
}
</style>
